/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none;}
.desktopOnly {display:inline-block;}


h1, .h1, h3.large {
    font-size:1.8em;
}
h2, .h2, #hero .caption {
    font-size: 1.4em;
}
 .bigger {font-size: 1.3em;}
h3, .h3, h4, .h4 {font-size: 1.15em;}
.submenu a {font-size: 0.9em;}


#homeMission {font-weight: 700;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.fullWidth #leftcol > div > div {
	padding: 2em 0;
}


/* Reduce padding on some rows*/
#breadcrumbs .row, #bookForm .row{padding: 1em 0;}

#content .row { padding: 0 0 2em 0;}

/*Increase padding content some rows */
#homeBlocks .row{
        padding: 4em 0;
    }




		
/*********************
SPRITE
*********************/
		

    #logo {
        width: 120px; height: 70px;
	    background-size: 120px 70px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {
    .button, button {@include border-radius($small-border-radius);}
	    .row {
		    position: relative;
            min-height: 90px;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
			top: 5%;
            right:0;
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:0%;
        }
        
     		
}

/***********************/
/*Confidence triggers*/
#confidence {
    position:relative;
    .row {
         background: white; z-index:1;
                  
        .os-animation:nth-of-type(1) {
		        -webkit-animation-delay: 0s; /* Safari 4.0 - 8.0 */ animation-delay: 0s; 
		    }
		
		 .os-animation:nth-of-type(2){
		        -webkit-animation-delay: 0.25s; /* Safari 4.0 - 8.0 */ animation-delay: 0.25s; 
		    }
		  .os-animation:nth-of-type(3){
		        -webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */ animation-delay: 0.5s; 
		    }
		  .os-animation:nth-of-type(4){
		         -webkit-animation-delay: 0.75s; /* Safari 4.0 - 8.0 */ animation-delay: 0.75s;
		    }
          .os-animation:nth-of-type(5){
		         -webkit-animation-delay: 1s; /* Safari 4.0 - 8.0 */ animation-delay: 1s;
		    }
           .os-animation:nth-of-type(6){
		         -webkit-animation-delay: 1.25s; /* Safari 4.0 - 8.0 */ animation-delay: 1.25s;
		    }

            .wrap {float:none; display:block; width:100%; margin-right:0em;
                   img {max-width:196px;}
            }

    }
}



/**************************
HERO - Home
***************************/
/*body.Home #imageslider {max-height: 570px; overflow:hidden;}*/
#hero {z-index:0;}
body:not(.Home) #hero {

	.topBlock {
       img {max-width:1200px;}
    }
}/*hero*/

body.Home #hero .row {
    width: 50%;
}

/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}

/*.menuWrap {
	width: 74.30939226%;
}*/
#mainMenu {
	/*padding: 1em 0 0.5em 0;*/
}
.topMenu {

    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;


        > li {
            display: inline-block;
            padding: 0em 1em 0.75em 1em;

            > a, > a.parent{
                 position: relative;
				 background: transparent;
                 padding: 0.25em 0em;

                 &:hover, &:focus {
					background:none;
				}
				
				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 2px;
					bottom: 0;
					left: 0;
					background-color: $color-primary;
					visibility: hidden;
					-webkit-transform: scaleX(0);
					transform: scaleX(0);
					@include transition(all 0.3s ease-in-out 0s);
				}
				&:hover:after, &.activerootmenulink:after  {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}

            }
			
			/*&.hover > a {
				&:after {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}
			}*/
			
            /*hide home link to public*/
            &:last-child {
                display: none;
            }
			
			> ul.submenu {
                opacity: 0;
                top:50%;
               
            /*
			Animation effect for top nav
			*/
				/*@include transition(top,opacity,visibility 0.2s linear);*/
                transition: 0.2s linear;
                transition-property: top,opacity,visibility;
				@include transition(all 0.2s linear);
				/* arrow */
				/*&:before {
					content:"";
					display:block;
					margin: 0 auto;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent #ffffff transparent;
				}*/

				li{ text-align:center;}
            }
            /* showing sub-menus */
            &:hover ul.submenu {
				
            }

        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
				visibility: hidden;
				box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
				

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 230px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;
						background: $white;

                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                        li a{background: darken($white, 5%);}
                    }
                }
            }
			
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

	.nav li.hover > ul {
        left: 0;
        visibility: visible;
    }

   .nav > li.hover > ul.submenu {
        opacity: 1;
        top: 100%;
		left:-60px;
		@include transition(all 0.2s linear);
    }

    .nav li li ul {
        left: -9999px;
        z-index: 9999;
        position: absolute;
    }

    .nav li li.hover ul {
        left: 100%;
        top: 0;
        z-index: 9999;
        position: absolute;
    }



/*********************
BLOCK LAYOUT
**********************/


.columnlayout, #gallery.columnlayout > li  {	
	> div, > li, > article {width:33%;	}

}


/*.columnlayout {	
	> div, li {	width:100%;	}

}*/



    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;

    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

    /*spacing needed for upcoming events blocks */
    .columnlayout.eventsBlocks {
       justify-content: space-between;
       > article, > div {	width: 46%;	}
    }

#bottomBlocks {
   .row .block {margin-top:2em;}
}	

/* CENTERED TEXT WITH OVERLAY HOVER EFFECT - Used on Gallery  */


.cText {
   a {
      max-height:355px;
   }
}

/****FOOTER********/

#footer {
        background: darken($black, 5%);
        /*text-align: center;*/
        > div {


            i {
                padding-right: 0.25em;
            }

            #copyright {
                &.first {
                    text-align:left;
                   
                }
                &.last{
                    text-align:right;
                   
                }
            }

           
            
        }
    }


/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				/*&:before {background-color: rgba(0, 0, 0, 0.25); }*/
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption.activate {
                   
                }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 0px; }
    .slick-prev { left: 0; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}


/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}

#CTA {
    position: fixed;
    display: block;
    opacity: 0;
    width: 50%;
    background: transparent;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    animation-delay: 0s;

    span.h2 {
        padding-left: 2em;
        @include transition(all 1s ease-in-out);
    }

    @include transition(all 1s ease-in-out);
    
    form {
        width: 100%;
        opacity: 0;
        @include transition(all 0.3s ease-in-out);
        transition-delay: 0;
        padding: 0;
        margin: 0em auto;
    }

    &:after {
        content: '';
        width: 150%;
        height: 100%;
        display: block;
        background: $color-primary;
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
        position: absolute;
        top: 0;
        left: -10%;
        z-index: -9999;
        @include transition(all 1s ease-in-out);
        transform: skew(-40deg);
    }

    &.expanded {
        background: transparent;
        width: 50%;
        bottom: 0;
        @include transition(all 1s ease-in-out);


        span.h2 {
            padding-left: 0;
            @include transition(all 1s ease-in-out);
        }
    }
}